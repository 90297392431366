import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useCallback, useRef } from "react"
import { useFlexSearch } from "react-use-flexsearch"

import logo from "../images/logo.png"

export const SearchIcon = () => (
  <svg viewBox="0 0 512 512">
    <path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
  </svg>
)

const Search = () => {
  const { localSearchArticles } = useStaticQuery(graphql`
    {
      localSearchArticles {
        index
        store
      }
    }
  `)
  const inputRef = useRef(null)
  const [query, setQuery] = useState("")
  const handleInput = useCallback(e => {
    setQuery(e.target.value)
  }, [])
  const results = useFlexSearch(
    query,
    localSearchArticles.index,
    localSearchArticles.store
  )

  return (
    <div className="search">
      <input
        type="text"
        ref={inputRef}
        value={query}
        onChange={handleInput}
        placeholder="Search"
      />
      <div className="search__icon" onClick={() => inputRef.current.focus()}>
        <SearchIcon />
      </div>
      {results.length > 0 && (
        <div className="search__results">
          {results.map(({ author, uid, title, subtitle }, i) => (
            <Link
              to={"/articles/" + uid}
              className="search__result"
              key={i}
              onClick={e => setQuery("")}
            >
              <span className="search__result-title">{title}</span>
              <span className="search__result-subtitle">{`${subtitle} — ${author}`}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

const Header = ({ siteTitle }) => (
  <header>
    <Link
      to="/"
      style={{
        color: `white`,
        textDecoration: `none`,
      }}
    >
      <img src={logo} />
    </Link>
    <Search />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
