import React, { useEffect } from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { graphql } from "gatsby"
import { Share } from "react-twitter-widgets"

import SEO from "../components/seo"

const Article = ({ data, location }) => {
  useEffect(() => {
    const quoteFootnotes = Array.prototype.slice.call(
      document.querySelectorAll(".footnote, .quote-footnote")
    )

    quoteFootnotes.forEach((qF, i) => {
      if (qF.classList.contains("quote-footnote")) {
        const s = document.createElement("span")
        s.className = "footnote"
        s.innerText = i + 1
        qF.appendChild(s)
      }
    })

    const footnotes = Array.prototype.slice.call(
      document.querySelectorAll(".footnote")
    )

    const references = Array.prototype.slice.call(
      document.querySelectorAll(".reference")
    )

    footnotes.forEach((foot, i) => {
      foot.addEventListener("click", () => {
        window.scrollTo(
          0,
          window.scrollY + references[i].getBoundingClientRect().top
        )
      })
    })

    references.forEach((ref, i) => {
      ref.addEventListener("click", () => {
        window.scrollTo(
          0,
          window.scrollY + footnotes[i].getBoundingClientRect().top
        )
      })
    })

    // const captions = Array.prototype.slice.call(
    //   document.querySelectorAll("img[alt]")
    // )
    // console.log("captions:", captions)
  }, [data.prismicArticle.data.content.html])

  return (
    <>
      <SEO
        title={`${
          data.prismicArticle.data.meta_title &&
          data.prismicArticle.data.meta_title !== ""
            ? data.prismicArticle.data.meta_title
            : data.prismicArticle.data.title
        }`}
        image={data.prismicArticle.data.hero_image.url}
        description={data.prismicArticle.data.meta_description}
        keywords={data.prismicArticle.data.meta_keywords}
      />
      {/* <pre>{JSON.stringify(data)}</pre> */}
      <div className="article">
        <div
          className="article-image"
          style={{
            backgroundImage: `url('${data.prismicArticle.data.hero_image.url}')`,
          }}
        ></div>
        {data.prismicArticle.data.hero_image.alt &&
          data.prismicArticle.data.hero_image.alt.length > 0 && (
            <p className="article-alt">
              {data.prismicArticle.data.hero_image.alt}
            </p>
          )}
        {/* <div className="article-hero">
          <h1>{data.prismicArticle.data.title}</h1>
          {data.prismicArticle.data.subtitle &&
            data.prismicArticle.data.subtitle.length > 0 && (
              <p>{data.prismicArticle.data.subtitle}</p>
            )}
          <p>By {data.prismicArticle.data.author}</p>
        </div> */}
        <div
          className="article-content"
          dangerouslySetInnerHTML={{
            __html: data.prismicArticle.data.content.html,
          }}
        ></div>
        <div className="article-share">
          <Share
            url={location.href}
            options={{
              text:
                data.prismicArticle.data.meta_title &&
                data.prismicArticle.data.meta_title !== ""
                  ? data.prismicArticle.data.meta_title
                  : data.prismicArticle.data.title,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default withPrismicPreview(Article)

export const query = graphql`
  query ($id: String!) {
    prismicArticle(id: { eq: $id }) {
      id
      _previewable
      data {
        author
        content {
          html
        }
        hero_image {
          alt
          url
        }
        subtitle
        title
        meta_title
        meta_description
        meta_keywords
      }
      uid
    }
  }
`
