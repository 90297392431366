import * as React from "react"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import { Link } from "gatsby"
import { PrismicProvider } from "@prismicio/react"
import Article from "./src/templates/article.js"
import Page from "./src/templates/page.js"

const linkResolver = doc => {
  return `/${doc.type}s/${doc.uid}`
}

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: "negation",
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
            article: Article,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  </PrismicProvider>
)
