/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, Script, useStaticQuery, graphql } from "gatsby"
import { useSpring, animated } from "@react-spring/web"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import Header from "../components/header"
import "./layout.scss"

import logoSmall from "../images/logo-small.png"
import MobileSearch from "../components/MobileSearch"

const MainNavigation = ({ data }) => {
  return (
    <div className="main-navigation">
      <Link to="/">
        <div
          className="main-navigation__logo"
          style={{
            backgroundImage: `url(${logoSmall})`,
          }}
        ></div>
      </Link>
      <div className="main-navigation__links">
        <Link to="/">Home</Link>
        <Link to="/archives/">Archives</Link>
        {data.allPrismicPage.edges.map(({ node }, i) => (
          <Link to={`/pages/${node.uid}/`} key={i}>
            {node.data.title}
          </Link>
        ))}
        <div className="main-navigation__social">
          {/* Patreon */}
          <a href="https://www.patreon.com/negationmagazine" target="_blank">
            <svg
              height="546px"
              version="1.1"
              viewBox="0 0 569 546"
              width="569px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Patreon logo</title>
              <g>
                <circle
                  cx="362.589996"
                  cy="204.589996"
                  data-fill="1"
                  r="204.589996"
                ></circle>
                <rect
                  data-fill="1"
                  height="545.799988"
                  width="100"
                  x="0"
                  y="0"
                ></rect>
              </g>
            </svg>
          </a>
          {/* ko-fi */}
          <a href="https://ko-fi.com/negationmagazine" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="64"
              viewBox="0 0 64 64"
              width="64"
            >
              <g
                fill="none"
                transform="matrix(1.9656019 0 0 1.9656019 0 -.0000006)"
              >
                <circle
                  cx="16.280001"
                  cy="16.280001"
                  fill="#29abe0"
                  r="16.280001"
                />
                <path
                  d="m22.257931 8.8h1.607617c3.100776 0 5.614452 2.513676 5.614452 5.614452v.330015c0 3.100776-2.513676 5.614452-5.614452 5.614452h-1.607617v1.689795c0 1.431128-1.160158 2.591286-2.591286 2.591286h-12.2353593c-1.4311276 0-2.5912857-1.160158-2.5912857-2.591286v-11.953071c0-.7155639.5800791-1.295643 1.2956429-1.295643zm0 2.996757v5.565405h1.465573c1.536844 0 2.782703-1.245858 2.782703-2.782702 0-1.536845-1.245859-2.782703-2.782703-2.782703z"
                  fill="#fff"
                />
              </g>
              <path
                d="m8.36 5.2768458c.4002942-1.1712306 1.3489414-1.7568458 2.845941-1.7568458 2.2455 0 3.077965 2.7936503 1.900934 4.62-.784687 1.2175665-2.366979 2.757567-4.746875 4.62-2.3798961-1.862433-3.9621877-3.4024335-4.746875-4.62-1.1770309-1.8263497-.3445663-4.62 1.9009337-4.62 1.4969999 0 2.4456471.5856152 2.8459413 1.7568458z"
                fill="#ff5e5b"
                transform="matrix(1.9656019 0 0 1.9656019 9.513513196 17.29729612)"
              />
            </svg>
          </a>
          {/* twitter */}
          <a href="https://twitter.com/NegationMag" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

const MobileNav = ({ data }) => {
  const [navIsOpen, setNavOpen] = useState(false)
  const props = useSpring({
    opacity: navIsOpen ? 1 : 0,
    pointerEvents: navIsOpen ? "initial" : "none",
  })
  const [navIsVisible, setNavVisible] = useState(true)
  const triggerProps = useSpring({
    opacity: navIsVisible ? 1 : 0,
    pointerEvents: navIsVisible ? "initial" : "none",
  })

  useScrollPosition(({ prevPos, currPos }) => {
    setNavVisible(currPos.y > prevPos.y)
  })

  return (
    <div className="mobile-navigation">
      <animated.div
        style={triggerProps}
        className="mobile-navigation__open"
        onClick={e => setNavOpen(true)}
      >
        <span></span>
        <span></span>
        <span></span>
      </animated.div>
      <animated.div className="mobile-navigation__menu" style={props}>
        <div
          className="mobile-navigation__close"
          onClick={e => setNavOpen(false)}
        ></div>
        <Link to="/" onClick={e => setNavOpen(false)}>
          Home
        </Link>
        <Link to="/archives/" onClick={e => setNavOpen(false)}>
          Archives
        </Link>
        {data.allPrismicPage.edges.map(({ node }, i) => (
          <Link
            to={`/pages/${node.uid}/`}
            key={i}
            onClick={e => setNavOpen(false)}
          >
            {node.data.title}
          </Link>
        ))}
        <div className="mobile-navigation__social">
          {/* Patreon */}
          <a href="https://www.patreon.com/negationmagazine" target="_blank">
            <svg
              height="546px"
              version="1.1"
              viewBox="0 0 569 546"
              width="569px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Patreon logo</title>
              <g>
                <circle
                  cx="362.589996"
                  cy="204.589996"
                  data-fill="1"
                  r="204.589996"
                ></circle>
                <rect
                  data-fill="1"
                  height="545.799988"
                  width="100"
                  x="0"
                  y="0"
                ></rect>
              </g>
            </svg>
          </a>
          {/* ko-fi */}
          <a href="https://ko-fi.com/negationmagazine" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="64"
              viewBox="0 0 64 64"
              width="64"
            >
              <g
                fill="none"
                transform="matrix(1.9656019 0 0 1.9656019 0 -.0000006)"
              >
                <circle
                  cx="16.280001"
                  cy="16.280001"
                  fill="#29abe0"
                  r="16.280001"
                />
                <path
                  d="m22.257931 8.8h1.607617c3.100776 0 5.614452 2.513676 5.614452 5.614452v.330015c0 3.100776-2.513676 5.614452-5.614452 5.614452h-1.607617v1.689795c0 1.431128-1.160158 2.591286-2.591286 2.591286h-12.2353593c-1.4311276 0-2.5912857-1.160158-2.5912857-2.591286v-11.953071c0-.7155639.5800791-1.295643 1.2956429-1.295643zm0 2.996757v5.565405h1.465573c1.536844 0 2.782703-1.245858 2.782703-2.782702 0-1.536845-1.245859-2.782703-2.782703-2.782703z"
                  fill="#fff"
                />
              </g>
              <path
                d="m8.36 5.2768458c.4002942-1.1712306 1.3489414-1.7568458 2.845941-1.7568458 2.2455 0 3.077965 2.7936503 1.900934 4.62-.784687 1.2175665-2.366979 2.757567-4.746875 4.62-2.3798961-1.862433-3.9621877-3.4024335-4.746875-4.62-1.1770309-1.8263497-.3445663-4.62 1.9009337-4.62 1.4969999 0 2.4456471.5856152 2.8459413 1.7568458z"
                fill="#ff5e5b"
                transform="matrix(1.9656019 0 0 1.9656019 9.513513196 17.29729612)"
              />
            </svg>
          </a>
          {/* twitter */}
          <a href="https://twitter.com/NegationMag" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </a>
        </div>
        <MobileSearch onClick={e => setNavOpen(false)} />
      </animated.div>
    </div>
  )
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicPage(filter: {data: {show_in_navigation: {eq: true}}}) {
        edges {
          node {
            id
            data {
              title
            }
            uid
          }
        }
      }
    }
  `)
  const HeaderMemo = useMemo(() => <Header siteTitle={"Negation"} />, [])
  const MainNavMemo = useMemo(() => <MainNavigation data={data} />, [data])
  const MobileNavMemo = useMemo(() => <MobileNav data={data} />, [data])

  return (
    <div className="app">
      {MainNavMemo}
      {MobileNavMemo}
      <div>
        {HeaderMemo}
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        ></footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
