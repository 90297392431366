import React from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"

const Page = ({ data }) => (
  <>
    <SEO title="Page" />
    {/* <pre>{JSON.stringify(data)}</pre> */}
    <div className="page">
      <h1>{data.prismicPage.data.title}</h1>
      <div
        className="page-content"
        dangerouslySetInnerHTML={{
          __html: data.prismicPage.data.content.html,
        }}
      ></div>
      {data.prismicPage.data.body.map((slice, i) => {
        if (slice.slice_type == "articles") {
          return (
            <>
              <h2 className="featured-title">{slice.primary.group_title}</h2>
              <div className="grid grid--featured">
                {slice.items.map(({ article }, j) => (
                  <Link
                    to={`/${article.type}s/${article.uid}`}
                    className="grid-item"
                    key={j}
                  >
                    <img
                      className="grid-item__mobile-image"
                      src={article.document.data.hero_image.url}
                    />
                    {article.document.data.subtitle && article.document.data.subtitle.length > 0 && (
                      <div className="grid-item__title">
                        <p>{article.document.data.subtitle}</p>
                      </div>
                    )}
                  </Link>
                ))}
              </div>
            </>
          )
        }

        if (slice.slice_type == "rich_text_1") {
          return (
            <div
              className="page-content"
              dangerouslySetInnerHTML={{
                __html: slice.primary.content_block.html,
              }}
              key={i}
            ></div>
          )
        }
      })}
    </div>
  </>
)

export default withPrismicPreview(Page)

export const query = graphql`
  query ($id: String!) {
    prismicPage(id: { eq: $id }) {
      id
      _previewable
      data {
        content {
          html
        }
        title
        body {
          ... on PrismicPageDataBodyRichText1 {
            primary {
              content_block {
                html
              }
            }
            slice_type
          }
          ... on PrismicPageDataBodyArticles {
            id
            primary {
              group_title
            }
            items {
              article {
                uid
                type
                document {
                  ... on PrismicArticle {
                    id
                    data {
                      hero_image {
                        url
                        alt
                      }
                      title
                      subtitle
                    }
                  }
                  ... on PrismicPage {
                    id
                    data {
                      hero_image {
                        url
                        alt
                      }
                      title
                      subtitle
                    }
                  }
                }
              }
            }
            slice_type
          }
        }
      }
      uid
    }
  }
`
