import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useCallback, useRef, useState } from "react"
import { useFlexSearch } from "react-use-flexsearch"
import { SearchIcon } from "./header"

const MobileSearch = ({ onClick }) => {
  const { localSearchArticles } = useStaticQuery(graphql`
    {
      localSearchArticles {
        index
        store
      }
    }
  `)
  const inputRef = useRef(null)
  const [query, setQuery] = useState("")
  const handleInput = useCallback(e => {
    setQuery(e.target.value)
  }, [])
  const results = useFlexSearch(
    query,
    localSearchArticles.index,
    localSearchArticles.store
  )

  return (
    <div className="mobile-search">
      <div className="mobile-search__input">
        <input
          type="text"
          ref={inputRef}
          value={query}
          onChange={handleInput}
          placeholder="Search"
        />
        <div
          className="mobile-search__icon"
          onClick={() => inputRef.current.focus()}
        >
          <SearchIcon />
        </div>
      </div>
      {results.length > 0 && (
        <div className="mobile-search__results">
          {results.map(({ author, uid, title, subtitle }, i) => (
            <Link
              to={"/articles/" + uid}
              className="mobile-search__result"
              key={i}
              onClick={() => {
                onClick()
                setQuery("")
              }}
            >
              <span className="mobile-search__result-title">{title}</span>
              <span className="mobile-search__result-subtitle">{`${subtitle} — ${author}`}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default MobileSearch
